import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Components/Home";
import Contact from "./Components/ContactSection";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Cookies from "./Components/Cookies";
import PrivacyPolicy from "./Components/Privacy";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Sass/App.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { PagesForSale } from "./Components/PagesForSale";
import { TheGiftWorldSiteInfo } from "./Components/TheGiftWorldSiteInfo";
import { BeckySmithVaSiteInfo } from "./Components/BeckySmithVaSiteInfo";
import { TeacherNewbieSiteInfo } from "./Components/TeacherNewbieSiteInfo";
import { JoshDarbySiteInfo } from "./Components/JoshDarbySiteInfo";

const App = (props) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/contact">
          <Navbar />
          <Contact />
          <Footer />
        </Route>
        <Route path="/cookies">
          <Navbar />
          <Cookies />
          <Footer />
        </Route>

        <Route path="/privacy-policy">
          <Navbar />
          <PrivacyPolicy />
          <Footer />
        </Route>

        <Route path="/pre-built-websites">
          <Navbar />
          <PagesForSale />
          <Footer />
        </Route>

        <Route path="/becky-smith-virtual-assistant-showcase">
          <Navbar />
          <BeckySmithVaSiteInfo />
          <Footer />
        </Route>

        <Route path="/chocolate-indulgence-website-showcase">
          <Navbar />
          <TheGiftWorldSiteInfo />
          <Footer />
        </Route>

        <Route path="/teacher-newbie-website-showcase">
          <Navbar />
          <TeacherNewbieSiteInfo />
          <Footer />
        </Route>

        <Route path="/josh-darby-website-showcase">
          <Navbar />
          <JoshDarbySiteInfo />
          <Footer />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
