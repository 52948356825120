import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import TheGiftWorldScreenshot from "../Images/TheGiftWorldScreenshotWide.webp";
import SEO from "../Components/SEO";

export const TheGiftWorldSiteInfo = () => {
  return (
    <Container fluid as="main" className="site-showcase">
      <SEO
        title="The Gift World Website | Bobby McGrath - Freelance Web Developer"
        description="Hi, I'm Bobby McGrath, a web developer based in Peterborough, UK. This page provides information on a website I built for an online gift store called The Gift World."
        image={`https://www.bobby-mcgrath.co.uk${TheGiftWorldScreenshot}`}
        imageDescription="Screenshot of Chocolate Indulgence's homepage"
        imageWidth="1200"
        imageHeight="630"
        url="https://www.bobby-mcgrath.co.uk/the-gift-world-website-showcase"
      />
      <Row data-aos="fade-in" as="section">
        <Col>
          <h1>
            <span>Chocolate Indulgence</span> Case Study
          </h1>
        </Col>
      </Row>
      <Row data-aos="fade-right" as="section">
        <Col>
          <p className="project-description">
            Chocolate Indulgence is a close and dear project to me as it is a
            website I built for my mum, she has been dreaming of establishing
            her own business for many years and she finally took the plunge and
            asked me to help her get started by helping her create a space
            online where she can sell personalised, handmade gifts online.
            <br />
            <br />
            The site itself is built in{" "}
            <a
              href="https://wordpress.org/download/"
              target="_blank"
              rel="noreferrer"
            >
              WordPress
            </a>{" "}
            using a bespoke handmade template with custom logic which is truly
            unique, I created it with SASS for the styling,{" "}
            <a href="https://woocommerce.com/" target="_blank" rel="noreferrer">
              and WooCommerce
            </a>{" "}
            for the payment gateway.
          </p>
        </Col>
      </Row>
      <Row data-aos="fade-left" as="section">
        <Col>
          <figure>
            <Image
              className="mt-5 shadow"
              fluid
              src={TheGiftWorldScreenshot}
              alt="Screenshot of The Gift World Website"
            />
            <figcaption>
              Screenshot of Chocolate Indulgence's Website
            </figcaption>
          </figure>
        </Col>
      </Row>
      <Row data-aos="fade-in" as="section">
        <Col>
          <a
            href="https://chocolate-indulgence.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="outline-primary" size="lg">
              Visit Site
            </Button>
          </a>
        </Col>
      </Row>
    </Container>
  );
};
