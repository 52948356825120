import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import WebsiteScreenshot from "../Images/BeckySmithVA.webp";
import CMS from "../Images/Becky-Smith-CMS.webp";
import WaveAudit from "../Images/Becky-Smith-WaveAudit.webp";
import LightHouseAudit from "../Images/Becky-Smith-LightHouseAudit.webp";
import Disclaimer from "./Disclaimer";
import SEO from "../Components/SEO";

export const BeckySmithVaSiteInfo = () => {
  const disclaimerText = `Please note that the domain for this website wasn't renewed so this site
  cannot be viewed from the original link anymore.
  <br />
  <br />
  However, I have kept this website running by hosting it on Netlify for demo purposes so
  you can still see my work.`;

  return (
    <Container fluid className="site-showcase" as="main">
      <SEO
        title="Becky Smith Website | Bobby McGrath - Freelance Web Developer"
        description="Hi, I'm Bobby McGrath, a web developer based in Peterborough, UK. This page provides information on a website I built for a London based virtual assistant called Becky Smith."
        image={`https://www.bobby-mcgrath.co.uk${WebsiteScreenshot}`}
        imageDescription="Screenshot of the Becky Smith VA homepage"
        imageWidth="1200"
        imageHeight="630"
        url="https://www.bobby-mcgrath.co.uk/becky-smith-virtual-assistant-showcase"
      />
      <Row data-aos="fade-in" as="section">
        <Col>
          <h1>
            <span>Becky Smith Virtual Assistant</span> Case Study
          </h1>
        </Col>
      </Row>
      <Row data-aos="fade-right" as="section">
        <Col>
          <p className="project-description">
            Becky Smith is a London based virtual assistant who tasked me to
            create her website for her to get more clients and exposure, we
            worked together on various concepts and ideas until we settled on
            creating a react based website with a headless CMS called{" "}
            <a href="https://strapi.io/" target="_blank" rel="noreferrer">
              Strapi
            </a>{" "}
            which would be hosted on{" "}
            <a href="https://www.heroku.com/" target="_blank" rel="noreferrer">
              Heroku
            </a>
            .
            <br />
            It was an interesting project which brought around many challenges
            as Strapi is an open source CMS so is changing constantly, but I
            ensured she got full control over the website with the images hosted
            on{" "}
            <a href="https://cloudinary.com/" target="_blank" rel="noreferrer">
              Cloudinary
            </a>{" "}
            to preserve load on the CMS and let the images be fetched more
            efficiently.
            <br />
            This resulted in a website which is fast, has bespoke SEO for each
            page which Becky is able to control and manage fully with SEO tips
            and guidance provided by myself as well as a website which fully
            adheres to principles of accessibility and usability on top of being
            responsive on both desktop, mobiles and tablets alike.
          </p>
        </Col>
      </Row>
      <Row data-aos="fade-left" as="section">
        <Col>
          <figure>
            <Image
              className="mt-5 shadow"
              fluid
              src={WebsiteScreenshot}
              alt="Screenshot of Teacher Newbie Website"
            />
            <figcaption>Screenshot of the BSVA website</figcaption>
          </figure>
        </Col>
      </Row>
      <Row data-aos="fade-right" className="mt-5" as="section">
        <Col>
          <figure>
            <Image
              className="mt-5 shadow"
              fluid
              src={CMS}
              alt="Screenshot of the CMS used to control the website content"
            />
            <figcaption>Screenshot of the Strapi CMS.</figcaption>
          </figure>
        </Col>
      </Row>
      <Row data-aos="fade-left" className="mt-5" as="section">
        <Col>
          <figure>
            <Image
              className="mt-5 shadow"
              fluid
              src={WaveAudit}
              alt="Screenshot showcasing the Wave accessibility score for the website."
            />
            <figcaption>
              Screenshot showcasing the Wave accessibility score for the
              website.
            </figcaption>
          </figure>
        </Col>
      </Row>
      <Row data-aos="fade-right" className="mt-5" as="section">
        <Col>
          <figure>
            <Image
              className="mt-5 shadow"
              fluid
              src={LightHouseAudit}
              alt="Screenshot showcasing the Google Lighthouse accessibility score for the website."
            />
            <figcaption>
              Screenshot showcasing the Google Lighthouse accessibility score
              for the website.
            </figcaption>
          </figure>
        </Col>
      </Row>
      <Row data-aos="fade-in" as="section">
        <Col>
          <Disclaimer text={disclaimerText} />
          <a
            href="https://beckysmithva.netlify.app/"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="outline-primary" size="lg">
              Visit Site
            </Button>
          </a>
        </Col>
      </Row>
    </Container>
  );
};
