import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import JoshDarbyHomePageScreenshot from "../Images/JoshDarbyHomePageScreenshot.webp";
import Disclaimer from "./Disclaimer";
import SEO from "../Components/SEO";

export const JoshDarbySiteInfo = () => {
  const disclaimerText = `Please note that the domain for this website wasn't renewed so this site
  cannot be viewed from the original link anymore.
  <br />
  <br />
  However, I have kept this website running by hosting it on Netlify for demo purposes so
  you can still see my work.`;
  return (
    <Container fluid as="main" className="site-showcase">
      <SEO
        title="Josh Darby Website | Bobby McGrath - Freelance Web Developer"
        description="Hi, I'm Bobby McGrath, a web developer based in Peterborough, UK. This page provides information on a website I built for a London based sessions drummer called Josh Darby."
        image={`https://www.bobby-mcgrath.co.uk${JoshDarbyHomePageScreenshot}`}
        imageDescription="Screenshot of the Josh Darby Sessions Drummer website homepage."
        imageWidth="1200"
        imageHeight="630"
        url="https://www.bobby-mcgrath.co.uk/josh-darby-website-showcase"
      />
      <Row data-aos="fade-in" as="section">
        <Col>
          <h1>
            <span>Josh Darby Website</span> Case Study
          </h1>
        </Col>
      </Row>
      <Row data-aos="fade-right" as="section">
        <Col>
          <p className="project-description">
            Josh is a London based sessions musician who needed a website to
            advertise his services to a wider audience, he contacted me via my
            website in order to help him achieve this. I built this site in
            React as opposed to WordPress as he didn't require a CMS to make his
            own edits, but also needed something which was quick to deploy which
            is why I choose that particular framework. I created the designs
            myself in Figma (which can be viewed{" "}
            <a
              href="https://www.figma.com/file/3s7WG3EQF6zTrDo1AIS7r9/Josh-Darby?node-id=0%3A3"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            ) and once we agreed upon the final structure (some pages were
            removed but I kept them in the designs in the event that he decides
            to re-incorporate them one day) of the site I used the designs to
            create the website which took around a day to complete. I also wrote
            all of the copy for the website making sure to focus on SEO keywords
            whilst keeping it feeling natural and human readable.
          </p>
        </Col>
      </Row>
      <Row data-aos="fade-left" as="section">
        <Col>
          <figure>
            <Image
              className="mt-5 shadow"
              fluid
              src={JoshDarbyHomePageScreenshot}
              alt="Screenshot of Josh Darby's Website"
            />
            <figcaption>Screenshot of Josh Darby's website</figcaption>
          </figure>
        </Col>
      </Row>
      <Row data-aos="fade-in" as="section">
        <Col>
          <Disclaimer text={disclaimerText} />
          <a
            href="https://josh-darby.netlify.app/"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="outline-primary" size="lg">
              Visit Site
            </Button>
          </a>
        </Col>
      </Row>
    </Container>
  );
};
