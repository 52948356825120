import React from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import HeroImage from "../Images/Me.webp";

export const Hero = () => {
  return (
    <section id="hero" className="mt-5 bg-primary ">
      <Container>
        <Row>
          <Col sm={4} md={9} lg={9} xl={9}>
            <Image
              className="mobile-hero-image"
              src={HeroImage}
              alt="Greyscale Image of Bobby McGrath"
            />
            <div data-aos="fade-up">
              <h1 className="h1 blurb">Freelance Web Developer UK</h1>
              <h2 className="text-white h2 blurb">
                <span id="highlight-one">
                  I'm a Peterborough based developer
                </span>{" "}
                with over{" "}
                <span id="highlight-two">
                  {new Date().getFullYear() - 2016} years
                </span>{" "}
                experience
              </h2>
              <a href="/contact">
                <Button variant="third" size="lg" className="mt-3 hero-button">
                  Find Out More
                </Button>
              </a>
            </div>
          </Col>
          <Col sm={4} md={3} lg={3} xl={3}>
            <Image
              data-aos="fade-up"
              className="img-fluid"
              src={HeroImage}
              alt="Greyscale Image of Bobby McGrath"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
